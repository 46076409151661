'use strict';

angular.module('enervexSalesappApp').controller('GlobalJobsCtrl', function(Auth, $scope, $state, GlobalJob,Flash, $stateParams, Util) {
	$scope.jobLabel = Util.jobLabel;
	$scope.isAdmin = Auth.isAdmin;
	$scope.accessAllAccounts = Auth.accessAllAccounts;
	$scope.ctx = {
		term: $stateParams.term,
		jobs:[],
		processing: false
	}
	Flash.clear()
	$scope.jobs = []
	$scope.search = function() {
		if (!$scope.ctx.term || $scope.ctx.term == ''){
			return
		}
		$scope.processing = true
		Flash.clear()
		Flash.create('info', '<strong>Searching</strong> jobs....', 0, {}, true);
		GlobalJob.query({
			term: $scope.ctx.term,
			limit: 200
		}).$promise.then(function(jobs){
			Flash.clear()
			if (jobs.length == 200) {
				Flash.create('danger', '<strong>Search</strong> Found over ' + jobs.length + " jobs please restrict your search term", 0, {}, true);
			} else {
				Flash.create('info', '<strong>Search</strong> Found ' + jobs.length + " jobs", 0, {}, true);
			}
			$scope.jobs = jobs;
			$scope.processing = false
			$state.go("global-jobs", {
				term: $scope.ctx.term
			}, {
				inherit: false,
				reload: false
			});
		});
	}
	$scope.search()
});
